<i18n>
{
  "de": {
    "yLabel": "Anzahl Gebäude",
    "total": "Total"
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="efficiency-class-chart">
    <template #default>
      <FaultsContainer :faults="projectionsByEfficiencyClassType.faulty" :portfolio="portfolio" />
      <BarChart :chart-data="chartData" :options="options" exportable />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer'
import FaultsContainer from '@/components/report/charts/FaultsContainer'
import BarChart from '@/components/shared/charts/BarChart'

import compassApi from '@/services/compass-api'
import colorPalettes from '@/services/color-palettes'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    BarChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    kpiType: {
      type: String,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      projectionsByEfficiencyClassType: null,
    }
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            stacked: true,
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value),
            },
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              title: (items) => this.getTooltipTitleWithTotal(items, { title: this.$t('total') }),
              label: this.getTooltipLabelWithPercentage,
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
          },
        },
      }
    },

    chartData() {
      var datasets = []
      for (const label in this.projectionsByEfficiencyClassType.projections) {
        if (Object.prototype.hasOwnProperty.call(this.projectionsByEfficiencyClassType.projections, label)) {
          datasets.push({
            label: label,
            backgroundColor: colorPalettes.efficiencyClassColors[label],
            data: this.projectionsByEfficiencyClassType.projections[label].count,
            lineTension: 0.1,
            borderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 10,
            borderColor: colorPalettes.efficiencyClassColors[label],
          })
        }
      }
      return {
        labels: this.projectionsByEfficiencyClassType.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    labelType() {
      this.refreshContent()
    },
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler() {
      const query = JSON.stringify(this.query)
      this.projectionsByEfficiencyClassType = await compassApi.call(
        `/projections/${this.portfolio.id}/efficiency_class/${this.kpiType}/${this.scenario.id}?query=${query}`
      )
    },
  },
}
</script>

<style>
.efficiency-class-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
