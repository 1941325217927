<i18n>
  {
    "de": {
      "yLabel": "Nennleistung (kWp)",
      "allBuildings": "Gesamtportfolio ({n} Liegenschaften)",
      "selectedBuildings": "Auswahl ({n} Liegenschaften)"
    }
  }
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="peak-production-chart">
    <template #default>
      <FaultsContainer :faults="data.faulty" :portfolio="portfolio" />
      <LineChart :chart-data="chartData" :options="options" exportable />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import LineChart from '@/components/shared/charts/LineChart.vue'

import compassApi from '@/services/compass-api.js'

export default {
  mixins: [AsyncContentMixin, ChartOptionsMixin],

  components: {
    LineChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      data: null,
      roundPrecision: 1,
    }
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxis: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (item) => this.getTooltipLabel(item, { precision: this.roundPrecision, unit: 'kWp' }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            display: true,
            reverse: true,
          },
        },
      }
    },

    chartData() {
      return {
        labels: this.data.years,
        datasets: [
          {
            label: this.$t(
              this.data.nbuildings !== this.portfolio.summary.total ? 'selectedBuildings' : 'allBuildings',
              { n: this.data.nbuildings }
            ),
            borderColor: 'rgba(50,0,200,0.9)',
            pointBackgroundColor: '#ffffff',
            pointHoverBorderWidth: 2,
            pointHoverRadius: 4,
            pointRadius: 2,
            borderWidth: 3,
            fill: false,
            lineTension: 0,
            pointHitRadius: 20,
            data: this.data.projections[this.scenario.id].value,
          },
        ],
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler() {
      const query = JSON.stringify(this.query)
      this.data = await compassApi.call(`/projections/${this.portfolio.id}/el_production_peak/scenarios?query=${query}`)
    },
  },
}
</script>

<style>
.peak-production-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
