<i18n>
{
  "de": {
    "yAreaLabel": "Total Fläche (m²)",
    "yCountLabel": "Anzahl Gebäude",
    "total": "Total"
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="efficiency-class-chart">
    <template #default>
      <FaultsContainer :faults="projectionsByEfficiencyClassType.faulty" :portfolio="portfolio" />
      <BarChart :chart-data="chartData" :options="options" exportable />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import BarChart from '@/components/shared/charts/BarChart.vue'

import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    AsyncContentMixin,
  ],

  components: {
    BarChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    kpiType: {
      type: String,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      projectionsByEfficiencyClassType: null,
    }
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            stacked: true,
          },
          yAxisArea: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value),
            },
            grid: {
              // hide as it only confuses with two axes
              drawOnChartArea: false,
              drawTicks: false,
            },
            title: {
              display: true,
              text: this.$t('yAreaLabel'),
            },
          },
          yAxisCount: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value),
            },
            position: 'right',
            grid: {
              // hide as it only confuses with two axes
              drawOnChartArea: false,
              drawTicks: false,
            },
            title: {
              display: true,
              text: this.$t('yCountLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              title: this.tooltipTitle,
              label: this.tooltipLabel,
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              filter: (legendItem) => {
                return legendItem.datasetIndex % 2 === 1 // strip duplicate labels
              },
            },
            onClick: (event, legendItem) => {
              const index = legendItem.datasetIndex
              const ci = event.chart
              const metaArea = ci.getDatasetMeta(index - 1) // also hide previous since those are the ones hidden for 2nd dataset
              const metaCount = ci.getDatasetMeta(index)

              metaArea.hidden = metaArea.hidden === null ? !ci.data.datasets[index - 1].hidden : null
              metaCount.hidden = metaCount.hidden === null ? !ci.data.datasets[index].hidden : null
              ci.update()
            },
          },
        },
      }
    },

    chartData() {
      var datasets = []
      for (const label in this.projectionsByEfficiencyClassType.projections) {
        if (Object.prototype.hasOwnProperty.call(this.projectionsByEfficiencyClassType.projections, label)) {
          datasets.push({
            label: label,
            backgroundColor: colorPalettes.efficiencyClassColors[label],
            data: this.projectionsByEfficiencyClassType.projections[label].area,
            lineTension: 0.1,
            borderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 10,
            borderColor: colorPalettes.efficiencyClassColors[label],
            stack: 'area',
            yAxisID: 'yAxisArea',
          })
          datasets.push({
            label: label,
            backgroundColor: colorPalettes.efficiencyClassColors[label],
            data: this.projectionsByEfficiencyClassType.projections[label].count,
            lineTension: 0.1,
            borderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 10,
            borderColor: colorPalettes.efficiencyClassColors[label],
            stack: 'count',
            yAxisID: 'yAxisCount',
          })
        }
      }
      return {
        labels: this.projectionsByEfficiencyClassType.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    labelType() {
      this.refreshContent()
    },
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler() {
      const query = JSON.stringify(this.query)
      this.projectionsByEfficiencyClassType = await compassApi.call(
        `/projections/${this.portfolio.id}/efficiency_class/${this.kpiType}/${this.scenario.id}?query=${query}`
      )
    },

    getTotal(xIndex) {
      return this.chartData.datasets.reduce((sum, v) => sum + v.data[xIndex], 0)
    },

    tooltipLabel(item) {
      if (item.datasetIndex % 2 === 1) {
        return null // strip duplicates
      }
      const label = item.dataset.label
      const yearIdx = item.dataIndex
      const amountArea = this.chartData.datasets[item.datasetIndex].data[yearIdx]
      const amountCount = this.chartData.datasets[item.datasetIndex + 1].data[yearIdx] // count index is always next. due to initial % 2 no index out of bounds
      const percentageArea = this.formatNumber(
        (amountArea /
          this.chartData.datasets.reduce((sum, { stack, data }) => (stack === 'area' ? sum + data[yearIdx] : sum), 0)) *
          100,
        0
      )
      const percentageCount = this.formatNumber(
        (amountCount /
          this.chartData.datasets.reduce(
            (sum, { stack, data }) => (stack === 'count' ? sum + data[yearIdx] : sum),
            0
          )) *
          100,
        0
      )
      return `${label}: ${this.formatNumber(amountArea, 0)} / ${this.formatNumber(
        amountCount,
        0
      )} (${percentageArea}% / ${percentageCount}%)`
    },

    tooltipTitle(items) {
      const yearIdx = items[0].dataIndex
      const totalArea = this.formatNumber(
        items.reduce((sum, { dataset: { stack, data } }) => (stack === 'area' ? sum + data[yearIdx] : sum), 0),
        0
      )
      const totalCount = this.formatNumber(
        items.reduce((sum, { dataset: { stack, data } }) => (stack === 'count' ? sum + data[yearIdx] : sum), 0),
        0
      )
      return `${this.$t('total')}: ${totalArea} / ${totalCount} (100%)`
    },
  },
}
</script>

<style>
.efficiency-class-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
