<i18n>
{
  "de": {
    "yLabel": "Investitionskosten (CHF)",
    "measureWINDOWS": "Fensterersatz",
    "measureROOF": "Sanierung Dach",
    "measureBASEMENT": "Sanierung Kellerdecke",
    "measureWALLS": "Sanierung Fassade",
    "measureHEATING": "Heizungsersatz",
    "measurePV": "Installation Photovoltaik",
    "measureElectricity": "Änderung Netzanschluss"
  }
}
</i18n>

<template>
  <div class="scenario-cost-chart">
    <BarChart :chart-data="chartData" :options="options" exportable />
  </div>
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BarChart from '@/components/shared/charts/BarChart.vue'

import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    BarChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    reportChartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    buildings() {
      return this.reportChartData.filter((b) => b.scenarios_data !== null)
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          xAxis: {
            stacked: true,
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value, 0),
            },
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              title: (items) => this.getTooltipTitleWithTotal(items, { title: items[0].label, unit: 'CHF' }),
              label: (item) => this.getTooltipLabel(item, { unit: 'CHF' }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
          },
        },
      }
    },

    chartData() {
      let datasets = []
      // TODO: add zones
      datasets.push({
        label: this.$t('measureElectricity'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.electricity
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['ELECTRICITY'],
        borderColor: colorPalettes.measureColors['ELECTRICITY'],
      })
      datasets.push({
        label: this.$t('measureHEATING'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.heating
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['HEATING'],
        borderColor: colorPalettes.measureColors['HEATING'],
      })
      datasets.push({
        label: this.$t('measurePV'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.pv
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['PV'],
        borderColor: colorPalettes.measureColors['PV'],
      })
      datasets.push({
        label: this.$t('measureWALLS'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.walls
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['WALLS'],
        borderColor: colorPalettes.measureColors['WALLS'],
      })
      datasets.push({
        label: this.$t('measureWINDOWS'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.windows
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['WINDOWS'],
        borderColor: colorPalettes.measureColors['WINDOWS'],
      })
      datasets.push({
        label: this.$t('measureROOF'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.roof
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['ROOF'],
        borderColor: colorPalettes.measureColors['ROOF'],
      })
      datasets.push({
        label: this.$t('measureBASEMENT'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.basement
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['BASEMENT'],
        borderColor: colorPalettes.measureColors['BASEMENT'],
      })
      return {
        labels: this.portfolio.scenarios.map((s) => s.description),
        datasets: datasets,
      }
    },
  },
}
</script>

<style>
.scenario-cost-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
